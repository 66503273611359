import BatchRegistersUpdateData from "@/services/loan-service/types/batch-registers-update-data";
import ListBatchRegistersParams from "@/services/loan-service/types/list-batch-registers-params";
import ListBatchesParams from "@/services/loan-service/types/list-batches-params";
import ListLoansParams from "@/services/loan-service/types/list-loans-params";
import Batch from "@/types/batch";
import BatchRegister, { ExtendedBatchRegister } from "@/types/batch-register";
import Borrower from "@/types/borrower";
import Company from "@/types/company";
import {
  ActiveCompaniesReport,
  AverageTicketOfLoansReport,
  CreditPortfolioReport,
  MonthData,
  PaidLoansMonthData,
  TotalDueInstallmentsReport,
  TotalInSimulationLoansReport
} from "@/types/dashboard-reports";
import Document from "@/types/document";
import IBankAccount from "@/types/IBankAccount";
import Loan from "@/types/loan";
import LoanInstallment from "@/types/loan-installment";
import LoanStatus from "@/types/loan-status";
import LoanStatusGroup from "@/types/loan-status-group";
import LoanType from "@/types/loan-type";
import NonEndorsementMotive from "@/types/non-endorsement-motive";
import LoanDebt from "../../types/loan-debt";
import LoanInstallmentRefinancing from "../../types/loan-installment-refinancing";
import Refinancing from "../../types/refinancing";
import HttpClient, { Response } from "../http-client";
import ActiveLoansReportResponse from "./types/active-loans-report";
import { AllowedToRefinancingLoan } from "./types/allowed-to-refinancing-loan";
import BatchUpdateStatusData from "./types/batch-update-status-data";
import GenerateBatchRegistersXlsParams from "./types/generate-batch-registers-xls-params";
import GenerateBatchRegistersXlsResponse from "./types/generate-batch-registers-xls-response";
import BatchesList from "./types/list-batches-response";
import LoansList from "./types/list-loans-response";
import LoanInstallmentsStatusReportResponse from "./types/loan-installments-status-report-response";
import {
  BorrowersLoansReportData,
  BorrowersLoansReportFilters,
  CompaniesLoansReportData,
  CompaniesLoansReportFilters,
  FilesRemittancesFundsReportFilters,
  FilesRemittancesFundsReportList,
  GenerateBorrowersLoansReportXlsData,
  GenerateBorrowersLoansReportXlsParams,
  GenerateCompaniesLoansReportXlsData,
  GenerateCompaniesLoansReportXlsParams,
  GenerateGroupsLoansReportXlsData,
  GenerateGroupsLoansReportXlsParams,
  GenerateLoanInstallmentsReportXlsData,
  GenerateLoanInstallmentsReportXlsParams,
  GenerateRemittancesFundsDetailsReportXlsParams,
  GenerateRemittancesFundsDetailsReportXlsData,
  GenerateRemittancesFundsReportXlsData,
  GenerateRemittancesFundsReportXlsParams,
  GroupsLoansReportData,
  GroupsLoansReportFilters,
  LoanInstallmentsReportData,
  LoanInstallmentsReportFilters,
  LoanRemittanceDetailsReportsFilters,
  LoanRemittanceDetailsReportsItemsList,
  LoanRemittanceReportsFilters,
  LoanRemittanceReportsItemsList,
  GenerateFileFundsReportXlsParams,
  GenerateFileFundsReportXlsData
} from "./types/management-reports";
import GenerateRemittanceCnabFromXlsParams from "./types/generate-remittance-cnab-from-xls-params";
import PixType from "@/types/pix-types";
import ListBatchRegisterParams from "./types/list-batch-register-params";
import ResendRefusedDebtPurchaseData from "./types/resend-refused-debt-purchase-data";
import ResendRefusedRefinancingData from "./types/resend-refused-refinancing-data";
import updateInstallmentsFromXlsParams from "./types/update-installments-from-xls-params";
import LoanInstallmentStatus from "@/types/loan-installment-status";

export default class LoanService extends HttpClient {
  private static classInstance?: LoanService;

  private constructor() {
    super(process.env.VUE_APP_LOAN_URL!);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new LoanService();
    }

    return this.classInstance;
  }

  public async listLoans(
    options: ListLoansParams
  ): Promise<Response<LoansList>> {
    return this.axiosCall<LoansList>({
      method: "GET",
      url: "/loans/list",
      params: options
    });
  }

  public async findRefinancingByLoanId(
    loanId: number
  ): Promise<Response<Refinancing>> {
    return this.axiosCall<Refinancing>({
      method: "GET",
      url: `/refinancings/find-by-loan-id/${loanId}`
    });
  }

  public async findDebtsByLoanId(
    loanId: number
  ): Promise<Response<LoanDebt[]>> {
    return this.axiosCall<LoanDebt[]>({
      method: "GET",
      url: `/debts/find-by-loan-id/${loanId}`
    });
  }

  public async resendRefusedDebtPurchase(
    data: ResendRefusedDebtPurchaseData
  ): Promise<any> {
    return this.axiosCall({
      method: "PATCH",
      url: `/loans/resend-debt-purchase/${data.loanId}`,
      data: data
    });
  }

  public async resendRefusedRefinancing(
    data: ResendRefusedRefinancingData
  ): Promise<any> {
    return this.axiosCall({
      method: "PATCH",
      url: `/loans/resend-refinancing/${data.loanId}`,
      data: data
    });
  }

  public async listAllowedToRefinancingLoans(
    cpf: string
  ): Promise<Response<AllowedToRefinancingLoan[]>> {
    return this.axiosCall<AllowedToRefinancingLoan[]>({
      method: "GET",
      url: `/loans/simulations/list-allowed-to-refinancing-by-cpf/${cpf}`
    });
  }

  public async approveLoan(data: { loanId: number }): Promise<Response<Loan>> {
    return this.axiosCall<Loan>({
      method: "PUT",
      url: `/loans/${data.loanId}/approve`,
      data: data
    });
  }

  public async updateLoanStatus(
    loanId: number,
    data: {
      status: string;
      observation: string;
      paidDate: string;
    }
  ): Promise<Response<Loan>> {
    return this.axiosCall<Loan>({
      method: "POST",
      url: `/loans/${loanId}/update-status`,
      data: data
    });
  }

  public async refuseLoan(data: {
    loanId: number;
    nonEndorsementMotiveId: number;
    endorsementObservation: string;
    nonEndorsementAvailableMargin: number;
  }): Promise<Response<Loan>> {
    return this.axiosCall<Loan>({
      method: "PUT",
      url: `/loans/${data.loanId}/refuse`,
      data: data
    });
  }

  public async cancelLoan(data: {
    loanId: number;
    cancelationMotive: string;
  }): Promise<Response<Loan>> {
    return this.axiosCall<Loan>({
      method: "PUT",
      url: `/loans/${data.loanId}/cancel`,
      data: data
    });
  }

  public async undoFiredEmployeeLoan(data: {
    loanId: number;
  }): Promise<Response<Loan>> {
    return this.axiosCall<Loan>({
      method: "POST",
      url: `/loans/${data.loanId}/undo-fired-employee`
    });
  }

  public async getLoan(id: number): Promise<Response<Loan>> {
    return this.axiosCall<Loan>({
      method: "GET",
      url: `/loans/get/${id}`
    });
  }

  public async getPixTypes(): Promise<Response<PixType[]>> {
    return this.axiosCall<PixType[]>({
      method: "GET",
      url: `/loans/list/pix-types`
    });
  }

  public async getStatusList(): Promise<Response<LoanStatus[]>> {
    return this.axiosCall<LoanStatus[]>({
      method: "GET",
      url: `/loans/list/status`
    });
  }

  public async getLoanInstallmentsStatusList(): Promise<
    Response<LoanInstallmentStatus[]>
  > {
    return this.axiosCall<LoanInstallmentStatus[]>({
      method: "GET",
      url: `/loans/list/installments/status`
    });
  }

  public async getStatusGroupList(): Promise<Response<LoanStatusGroup[]>> {
    return this.axiosCall<LoanStatusGroup[]>({
      method: "GET",
      url: `/loans/list/status-groups`
    });
  }

  public async getSimulationTypesList(): Promise<Response<LoanStatusGroup[]>> {
    return this.axiosCall<LoanStatusGroup[]>({
      method: "GET",
      url: `/loans/list/simulation-types`
    });
  }

  public async getTypesList(): Promise<Response<LoanType[]>> {
    return this.axiosCall<LoanType[]>({
      method: "GET",
      url: `/loans/list/types`
    });
  }

  public async getLoanInstallments(
    id: number
  ): Promise<Response<LoanInstallment[]>> {
    return this.axiosCall<LoanInstallment[]>({
      method: "GET",
      url: `/loans/${id}/installments`
    });
  }

  public async getLoanBorrowersByCompany(
    companyId: number
  ): Promise<Response<Borrower[]>> {
    return this.axiosCall<Borrower[]>({
      method: "GET",
      url: `/loans/get-loans-borrowers/${companyId}`
    });
  }

  public async listBatches(
    params: ListBatchesParams
  ): Promise<Response<BatchesList>> {
    return this.axiosCall<BatchesList>({
      method: "GET",
      url: `/batches/list`,
      params
    });
  }

  public async listBatchRegisters(
    params: ListBatchRegistersParams
  ): Promise<Response<ExtendedBatchRegister[]>> {
    return this.axiosCall<ExtendedBatchRegister[]>({
      method: "GET",
      url: `/batch-registers/list`,
      params
    });
  }

  public async generateBatchRegistersXls(
    params: GenerateBatchRegistersXlsParams
  ): Promise<Response<GenerateBatchRegistersXlsResponse>> {
    return this.axiosCall<GenerateBatchRegistersXlsResponse>({
      method: "GET",
      url: `/batch-registers/generate-xls`,
      params
    });
  }

  public async updateBatchRegisters(
    data: BatchRegistersUpdateData
  ): Promise<any> {
    return this.axiosCall({
      method: "PATCH",
      url: `/batch-registers/update-multiple`,
      data: data
    });
  }

  public async updateBatchStatus(
    id: number,
    data: BatchUpdateStatusData
  ): Promise<any> {
    return this.axiosCall({
      method: "POST",
      url: `/batches/${id}/update-status`,
      data: data
    });
  }

  public async closeBatchAndGenerateBankSlip(
    id: number
  ): Promise<Response<Batch>> {
    return this.axiosCall<Batch>({
      method: "POST",
      url: `/batches/${id}/close-and-generate-bankslip`
    });
  }

  public async generateBankSlip(id: number): Promise<Response<void>> {
    return this.axiosCall<void>({
      method: "POST",
      url: `/batches/${id}/generate-bankslip`
    });
  }

  public async listAllNonEndorsementMotive(): Promise<
    Response<NonEndorsementMotive[]>
  > {
    return this.axiosCall<NonEndorsementMotive[]>({
      method: "GET",
      url: `/loans/non-endorsement-motives`
    });
  }

  public async getGroupsLoansReport({
    partnerIds,
    ...filters
  }: GroupsLoansReportFilters): Promise<Response<GroupsLoansReportData>> {
    return this.axiosCall<GroupsLoansReportData>({
      method: "GET",
      url: `/reports/loans-by-groups`,
      params: {
        ...filters,
        partnerIds: partnerIds?.join(",")
      }
    });
  }

  public async getCompaniesLoansReport({
    partnerIds,
    ...filters
  }: CompaniesLoansReportFilters): Promise<Response<CompaniesLoansReportData>> {
    return this.axiosCall<CompaniesLoansReportData>({
      method: "GET",
      url: `/reports/loans-by-companies`,
      params: {
        ...filters,
        partnerIds: partnerIds?.join(",")
      }
    });
  }

  public async getBorrowersLoansReport(
    filters: BorrowersLoansReportFilters
  ): Promise<Response<BorrowersLoansReportData>> {
    return this.axiosCall<BorrowersLoansReportData>({
      method: "GET",
      url: `/reports/loans-with-borrowers`,
      params: filters
    });
  }

  public async getLoanInstallmentsReport(
    filters: LoanInstallmentsReportFilters
  ): Promise<Response<LoanInstallmentsReportData>> {
    return this.axiosCall<LoanInstallmentsReportData>({
      method: "GET",
      url: `/reports/loans-with-detail`,
      params: filters
    });
  }

  public async generateGroupsLoansReportXls(
    params: GenerateGroupsLoansReportXlsParams
  ): Promise<Response<GenerateGroupsLoansReportXlsData>> {
    return this.axiosCall<GenerateGroupsLoansReportXlsData>({
      method: "POST",
      url: `/reports/loans-by-groups/generate-xls-token`,
      params
    });
  }

  public async generateRemittancesFundsReportXls(
    params: GenerateRemittancesFundsReportXlsParams
  ): Promise<Response<GenerateRemittancesFundsReportXlsData>> {
    return this.axiosCall<GenerateRemittancesFundsReportXlsData>({
      method: "POST",
      url: `/reports/loan-remittance/generate-xls-token`,
      params
    });
  }

  public async generateFileFundsReportXls(
    params: GenerateFileFundsReportXlsParams
  ): Promise<Response<GenerateFileFundsReportXlsData>> {
    return this.axiosCall<GenerateFileFundsReportXlsData>({
      method: "POST",
      url: `/reports/loan-file-funds/generate-xls-token`,
      params
    });
  }

  public async generateCompaniesLoansReportXls(
    params: GenerateCompaniesLoansReportXlsParams
  ): Promise<Response<GenerateCompaniesLoansReportXlsData>> {
    return this.axiosCall<GenerateCompaniesLoansReportXlsData>({
      method: "POST",
      url: `/reports/loans-by-companies/generate-xls-token`,
      params
    });
  }

  public async generateBorrowersLoansReportXls(
    params: GenerateBorrowersLoansReportXlsParams
  ): Promise<Response<GenerateBorrowersLoansReportXlsData>> {
    return this.axiosCall<GenerateBorrowersLoansReportXlsData>({
      method: "POST",
      url: `/reports/loans-with-borrowers/generate-xls-token`,
      params
    });
  }

  public async generateRemittanceDetailsFundsReportXls(
    params: GenerateRemittancesFundsDetailsReportXlsParams
  ): Promise<Response<GenerateRemittancesFundsDetailsReportXlsData>> {
    return this.axiosCall<GenerateRemittancesFundsDetailsReportXlsData>({
      method: "POST",
      url: `/reports/loan-remittance-details/generate-xls-token`,
      params
    });
  }

  public async generateLoanInstallmentsLoansReportXls(
    params: GenerateLoanInstallmentsReportXlsParams
  ): Promise<Response<GenerateLoanInstallmentsReportXlsData>> {
    return this.axiosCall<GenerateLoanInstallmentsReportXlsData>({
      method: "POST",
      url: `/reports/loans-with-detail/generate-xls-token`,
      params
    });
  }

  public async creditPortfolioReport(
    period: string
  ): Promise<Response<CreditPortfolioReport>> {
    return this.axiosCall<CreditPortfolioReport>({
      method: "GET",
      url: `/reports/credit-portfolio`,
      params: { period }
    });
  }

  public async activeCompaniesReport(
    period: string
  ): Promise<Response<ActiveCompaniesReport>> {
    return this.axiosCall<ActiveCompaniesReport>({
      method: "GET",
      url: `/reports/active-companies`,
      params: { period }
    });
  }

  public async averageTicketOfLoansReport(
    period: string
  ): Promise<Response<AverageTicketOfLoansReport>> {
    return this.axiosCall<AverageTicketOfLoansReport>({
      method: "GET",
      url: `/reports/average-ticket-of-loans`,
      params: { period }
    });
  }

  public async totalInSimulationLoansReport(
    period: string
  ): Promise<Response<TotalInSimulationLoansReport>> {
    return this.axiosCall<TotalInSimulationLoansReport>({
      method: "GET",
      url: `/reports/total-in-simulation-loans`,
      params: { period }
    });
  }

  public async totalDueInstallmentsReport(
    period: string
  ): Promise<Response<TotalDueInstallmentsReport>> {
    return this.axiosCall<TotalDueInstallmentsReport>({
      method: "GET",
      url: `/reports/total-due-installments`,
      params: { period }
    });
  }

  public async paidLoansReport(): Promise<Response<PaidLoansMonthData[]>> {
    return this.axiosCall<PaidLoansMonthData[]>({
      method: "GET",
      url: `/reports/paid-loans-by-month`
    });
  }

  public async loansGroupsDashboardActiveGroupsByMonthReport(): Promise<
    Response<MonthData[]>
  > {
    return this.axiosCall<MonthData[]>({
      method: "GET",
      url: `/reports/loans-groups-dashboard/active-groups-by-month`
    });
  }

  public async loansGroupsDashboardActiveLoansByMonthReport(): Promise<
    Response<ActiveLoansReportResponse>
  > {
    return this.axiosCall<ActiveLoansReportResponse>({
      method: "GET",
      url: `/reports/loans-groups-dashboard/active-loans-by-month`
    });
  }

  public async loansCompaniesDashboardActiveCompaniesByMonthReport(params: {
    groupId?: number | null;
  }): Promise<Response<MonthData[]>> {
    return this.axiosCall<MonthData[]>({
      method: "GET",
      url: `/reports/loans-companies-dashboard/active-companies-by-month`,
      params
    });
  }

  public async loansCompaniesDashboardActiveLoansByMonthReport(params: {
    groupId?: number | null;
  }): Promise<Response<ActiveLoansReportResponse>> {
    return this.axiosCall<ActiveLoansReportResponse>({
      method: "GET",
      url: `/reports/loans-companies-dashboard/active-loans-by-month`,
      params
    });
  }

  public async loansWithDetailDashboardActiveLoansByMonthReport(params: {
    groupId?: number | null;
  }): Promise<Response<ActiveLoansReportResponse>> {
    return this.axiosCall<ActiveLoansReportResponse>({
      method: "GET",
      url: `/reports/loans-with-detail-dashboard/active-loans-by-month`,
      params
    });
  }

  public async loansWithDetailDashboardLoanInstallmentsStatusByMonthReport(params: {
    groupId?: number | null;
  }): Promise<Response<LoanInstallmentsStatusReportResponse>> {
    return this.axiosCall<LoanInstallmentsStatusReportResponse>({
      method: "GET",
      url: `/reports/loans-with-detail-dashboard/loan-installments-status-by-month`,
      params
    });
  }

  public async getFilesRemittancesReports(
    params: FilesRemittancesFundsReportFilters
  ): Promise<Response<FilesRemittancesFundsReportList>> {
    return this.axiosCall<FilesRemittancesFundsReportList>({
      method: "GET",
      url: `/reports/remittance`,
      params
    });
  }

  public async getLoanRemittanceReports(
    params: LoanRemittanceReportsFilters
  ): Promise<Response<LoanRemittanceReportsItemsList>> {
    return this.axiosCall<LoanRemittanceReportsItemsList>({
      method: "GET",
      url: `/reports/loan-remittance`,
      params
    });
  }

  public async getLoanRemittanceDetailsReports(
    params: LoanRemittanceDetailsReportsFilters
  ): Promise<Response<LoanRemittanceDetailsReportsItemsList>> {
    return this.axiosCall<LoanRemittanceDetailsReportsItemsList>({
      method: "GET",
      url: `/reports/remittance/details`,
      params
    });
  }

  public async generateRemittanceCnabFromXls({
    file,
    financialId
  }: GenerateRemittanceCnabFromXlsParams): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("financialId", financialId.toString());

    return this.axiosCall({
      method: "POST",
      url: "/remittances/generate-remittance-cnab-from-xls",
      data: formData
    });
  }

  public async updateInstallmentsDueDateFromXls({
    file,
    financialId
  }: updateInstallmentsFromXlsParams): Promise<any> {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("financialId", financialId.toString());

    return this.axiosCall({
      method: "POST",
      url: "/remittances/update-installments-due-date-from-xls",
      data: formData
    });
  }

  public async updateLoanBankingData(
    id: number,
    data: IBankAccount
  ): Promise<any> {
    return this.axiosCall({
      method: "PATCH",
      url: `/loans/update-banking-data/${id}`,
      data: data
    });
  }

  public async getSimulation(
    cpf: string
  ): Promise<Response<Omit<SaveLoanResponse, "marginBaseRegister">>> {
    return this.axiosCall({
      method: "GET",
      url: `/loans/simulations/${cpf}/get`
    });
  }

  public async saveLoanType(params: {
    cpf: string;
    data: SaveLoanTypeData;
  }): Promise<Response<SaveLoanResponse>> {
    return this.axiosCall({
      method: "PATCH",
      url: `/loans/simulations/${params.cpf}/update`,
      data: params.data
    });
  }

  public async saveLoanInitialData(
    data: SaveLoanInitialData
  ): Promise<Response<SaveLoanResponse>> {
    if (data.loan && data.loan.id && data.borrower && data.borrower.cpf) {
      return this.axiosCall({
        method: "PATCH",
        url: `/loans/simulations/${data.borrower.cpf}/update`,
        data
      });
    }

    return this.axiosCall({
      method: "PUT",
      url: `/loans/simulations/create`,
      data
    });
  }

  public async saveLoanPersonalData(
    data: SaveLoanPersonalData
  ): Promise<Response<SaveLoanResponse>> {
    return this.axiosCall({
      method: "PATCH",
      url: `/loans/simulations/${data.borrower.cpf}/update`,
      data
    });
  }

  public async saveLoanCalculate(params: {
    cpf: string;
    data: SaveLoanCalculateData;
  }): Promise<Response<SaveLoanResponse>> {
    return this.axiosCall({
      method: "PATCH",
      url: `/loans/simulations/${params.cpf}/update`,
      data: params.data
    });
  }

  public async saveLoanResume(params: {
    cpf: string;
    data: SaveLoanResumeData;
  }): Promise<Response<SaveLoanResponse>> {
    return this.axiosCall({
      method: "PATCH",
      url: `/loans/simulations/${params.cpf}/update`,
      data: params.data
    });
  }

  public async saveLoanMarginBaseRegister(params: {
    cpf: string;
    data: SaveLoanMarginBaseRegister;
  }): Promise<Response<SaveLoanResponse>> {
    return this.axiosCall({
      method: "PATCH",
      url: `/loans/simulations/${params.cpf}/update`,
      data: params.data
    });
  }

  public async saveLoanDocuments(params: {
    cpf: string;
    data: SaveLoanDocumentsData;
  }): Promise<Response<SaveLoanDocumentsResponse>> {
    return this.axiosCall({
      method: "PUT",
      url: `/loans/simulations/${params.cpf}/document`,
      data: params.data
    });
  }

  public async getLoanResume(
    cpf: string
  ): Promise<Response<Loan & { firstInstallmentDueDate: string }>> {
    return this.axiosCall({
      method: "GET",
      url: `/loans/simulations/${cpf}/resume`
    });
  }

  public async finishLoan(cpf: string): Promise<Response<SaveLoanResponse>> {
    return this.axiosCall({
      method: "PATCH",
      url: `/loans/simulations/${cpf}/finish`,
      data: { cpf }
    });
  }

  public async calculatePresentValue(
    loanInstallmentIds: number[],
    presentDate?: string
  ): Promise<Response<CalculatePresentValueResponseDTO>> {
    return this.axiosCall({
      method: "GET",
      url: `/loans/calculate-present-value`,
      params: {
        loanInstallmentIds: loanInstallmentIds.join(","),
        presentDate: presentDate
      }
    });
  }

  public async updateLoanCompany(
    loanId: number,
    data: {
      companyId: string;
    }
  ): Promise<Response<Loan>> {
    return this.axiosCall({
      method: "PATCH",
      url: `/loans/update-loan-company/${loanId}`,
      data: data
    });
  }

  public async updateBatchRegisterBatchId(
    batchRegisterId: number,
    data: {
      batchId: number;
      companyId: number;
    }
  ): Promise<Response<BatchRegister>> {
    return this.axiosCall({
      method: "PATCH",
      url: `/batch-registers/update-batch-register-owner/${batchRegisterId}`,
      data: data
    });
  }
}

export interface SaveLoanInitialData {
  borrower: {
    cpf: string;
    name: string;
    phone: string;
  };
  user: {
    name: string;
    email: string;
    phone: string;
  };
  loan: {
    id?: number;
  };
}

export interface SaveLoanPersonalData {
  borrower: {
    cpf: string;
    name: string;
    birthDate: string;
    zipCode: string;
    address: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
    phone: string;
    homePhone: string;
    rg: string;
    rgIssuingAgency: string;
    rgIssuedState: string;
    birthPlace: string;
    maritalStatus: string;
    motherName: string;
  };
  user: {
    name: string;
    email: string;
    phone: string;
  };
  loan: {
    id: number;
  };
}

export interface SaveLoanResponse {
  borrower: {
    id: number;
    userId: number;
    clicksignSignerId: number;
    name: string;
    cpf: string;
    birthDate: string;
    phone: string;
    zipCode: string;
    address: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
    homePhone: string;
    rg: string;
    rgIssuingAgency: string;
    rgIssuedState: string;
    birthPlace: string;
    maritalStatus: string;
    motherName: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
  };
  loan: {
    id: number;
    typeId: number;
    type: {
      id: number;
      name: string;
    };
    userId: number;
    borrowerId: number;
    partnerId: number;
    simulationTypeId: number;
    companyId: number;
    bankingId?: number | null;
    bankingName?: string | null;
    administratorId?: number | null;
    administratorName?: string | null;
    bank: string;
    bankNationalCode: string;
    branch: string;
    checkingAccount: string;
    accountType: string;
    requestedAmount: number;
    numInstallments: number;
    withInsurance: boolean;
    requestObservation?: string;
    marginBaseRegisterId?: number | null;
    installments?: any[];
    debts: LoanDebt[];
    refinancing: Refinancing;
    documents?: {
      type: {
        name: string;
      };
      url: string;
    }[];
    pixType: LoanStatus;
    pixTypeId: number | null;
    pixKey?: string | null;
    loanPaymentTypeId?: number;
  };
  user: {
    id: number;
    status: number;
    flag: number;
    type: string;
    phone: string;
    name: string;
    email: string;
  };
  marginBaseRegister: {
    id: number;
    liquidIncome: number;
    selfDeclaredLiquidIncome: number;
    admissionDate: string;
    company: Company;
  };
}

export interface UpdateLoanData {
  companyId?: number;
  financialInstitutionId?: number;
  requestedAmount?: string;
  total?: string;
  numInstallments?: string;
  installmentValue?: string;
  clicksignDocumentKey?: string;
  clicksignSignatureKey?: string;
  checkingAccount?: string;
  bank?: string;
  branch?: string;
  accountType?: number;
  withInsurance?: boolean;
  proposalCode?: string;
  monthlyFee?: string;
  annualFee?: string;
  monthlyCET?: string;
  annualCET?: string;
  iofValue?: string;
  iofPercentage?: string;
  tacValue?: string;
  creditAmount?: string;
  insuranceValue?: string;
}

export interface SaveLoanMarginBaseRegister {
  marginBaseRegister: {
    id: number;
  };
}

export interface SaveLoanTypeData {
  loan: {
    type: string;
    numInstallments: number;
    withInsurance: boolean;
    bankingId: number;
    bankingName: string;
    administratorId: number;
    administratorName: string;
  };
}

export interface SaveLoanCalculateData {
  loan: {
    requestedAmount?: string;
    total?: string;
    numInstallments?: string;
    installmentValue?: string;
    checkingAccount?: string;
    bank?: string;
    bankNationalCode?: string;
    branch?: string;
    accountType?: number;
    withInsurance?: boolean;
    proposalCode?: string;
    monthlyFee?: string;
    annualFee?: string;
    monthlyCET?: string;
    annualCET?: string;
    iofValue?: string;
    iofPercentage?: string;
    tacValue?: string;
    creditAmount?: string;
    insuranceValue?: string;
    requestObservation?: string;
    debts?: LoanDebt[];
    refinancing?: Refinancing;
    pixTypeId?: number;
    loanPaymentTypeId?: number;
    pixKey?: string;
    bankingId?: number;
    bankingName?: string;
    administratorId?: number;
    administratorName?: string;
  };
  marginBaseRegister?: {
    id?: number;
    liquidAmount?: number;
  };
}

export interface SaveLoanResumeData {
  loan: {
    bankingId: number;
    bankingName: string;
    administratorId: number;
    administratorName: string;
  };
}

export interface SaveLoanDocumentsData {
  type: string;
  urls: string[];
  observation?: string;
}

export type SaveLoanDocumentsResponse = Array<Document>;

type CalculatePresentValueResponseDTO = Array<{
  loanInstallmentId: number;
  presentValue: number;
}>;
