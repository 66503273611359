import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/es5/util/colors";

import pt from "vuetify/src/locale/pt";
// import "../assets/variables.scss";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { pt },
    current: "pt",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#485CC7",
        accent: "#F5F6FA",
        secondary: "#FCE300",
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        // success: colors.green.accent3,
      },
      dark: {
        primary: "#485CC7",
        accent: "#F5F6FA",
        secondary: "#FCE300",
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
      },
    },
  },
});

